var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-button",
    {
      class: {
        "btn-multiple-state": true,
        "show-spinner": _vm.processing,
        "show-success": !_vm.processing && _vm.error === false,
        "show-fail": !_vm.processing && _vm.error,
      },
      attrs: {
        variant: _vm.variant,
        size: _vm.size,
        type: "submit",
        disabled: _vm.processing || _vm.disabled,
      },
    },
    [
      _c("span", { staticClass: "spinner d-inline-block" }, [
        _c("span", { staticClass: "bounce1" }),
        _vm._v(" "),
        _c("span", { staticClass: "bounce2" }),
        _vm._v(" "),
        _c("span", { staticClass: "bounce3" }),
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "icon success" }, [
        _c("i", { staticClass: "simple-icon-check" }),
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "icon fail" }, [
        _c("i", { staticClass: "simple-icon-exclamation" }),
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "label" }, [_vm._v(_vm._s(_vm.label))]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }