<template>
  <b-button
    :variant="variant"
    :size="size"
    type="submit"
    :disabled="processing || disabled"
    :class="{
      'btn-multiple-state': true,
      'show-spinner': processing,
      'show-success': !processing && error === false,
      'show-fail': !processing && error
    }"
  >
    <span class="spinner d-inline-block">
      <span class="bounce1"></span>
      <span class="bounce2"></span>
      <span class="bounce3"></span>
    </span>
    <span class="icon success">
      <i class="simple-icon-check"></i>
    </span>
    <span class="icon fail">
      <i class="simple-icon-exclamation"></i>
    </span>
    <span class="label">{{ label }}</span>
  </b-button>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: 'button'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    processing: {
      type: Boolean,
      default: false
    },
    error: {
      type: Boolean,
      default: null
    },
    size: {
      type: String,
      default: 'md'
    },
    variant: {
      type: String,
      default: 'primary'
    }
  }
}
</script>
